<template>
    <v-container>
        <div class="d-flex justify-content-center ">

            <router-link 
            tag="a" 
            class="appItem" 
            v-for="(item, index) in appItems" :key="index"
            to="/about"
            >
                <v-icon
                large
                color="white darken-2"
                >
                mdi-format-list-bulleted-square
                </v-icon>
                {{ item }}
            </router-link>
        </div>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            appItems:["Vụ gieo trồng","Nhật ký", "Bla bla bla"]
        }
    },
    methods: {
        
    }
}
</script>
<style lang="scss" scoped>
.appItem{
    width: 138px;
    height: 138px;
    display: flex;
    flex-direction: column;
    color: white;
    background-color: #A1887F; //material 300
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px;
    border-radius: 50%;
    border: 5px solid gainsboro;
    text-decoration: none;
}
.justify-content-center{
    justify-content: center;
}
</style>